import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import styled from '@emotion/styled';
import Typography from '../../text/Typography';
import PrimaryButton from '../../buttons/PrimaryButton';
import Image from '../../Image';
import { Document } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const CardContainer = styled.div`
  display: flex;
  height: 509px;
  min-width: 320px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.16) 99.5%);
  box-shadow: 0px 0px 24px 2px rgba(102, 115, 160, 0.07);
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]}px;
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  padding: ${spacing[5]}px;
  height: fill-available;
  width: fill-available;
`;

const LogoImage = styled(Image)`
  height: ${spacing[4]}px;
  width: auto;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7473%) hue-rotate(184deg)
    brightness(103%) contrast(100%);
  ${breakpoints.tablet} {
    height: ${spacing[5]}px;
  }
`;

export default function PartnerCaseCard({
  partnerCase,
  nrOfCases,
}: {
  partnerCase?: {
    logo?: string;
    logoAlt: string;
    imageUrl?: string;
    imageAlt: string;
    text?: Document;
    link?: {
      title?: string;
      url?: string;
    };
  };
  nrOfCases: number;
}) {
  if (!partnerCase) return null;

  return (
    <CardContainer>
      {partnerCase.imageUrl && (
        <ImageWrapper>
          <BackgroundImage
            maxWidth={{ desktop: 1200 }}
            lazyLoad
            url={partnerCase.imageUrl}
            alt={partnerCase.imageAlt ?? ''}
          />
          <ImageOverlay />
        </ImageWrapper>
      )}

      <CardContentWrapper>
        <TopWrapper>
          {partnerCase.logo && (
            <LogoImage maxWidth={200} url={partnerCase.logo} alt={partnerCase.logoAlt} />
          )}

          {partnerCase.text && (
            <Typography variant={nrOfCases < 3 ? 'h5Bold' : 'h6'} color={colors.white}>
              {documentToPlainTextString(partnerCase.text)}
            </Typography>
          )}
        </TopWrapper>

        {partnerCase.link?.url && partnerCase.link?.title && (
          <PrimaryButton
            color="white"
            size="small"
            style={{ width: 'fit-content' }}
            label={partnerCase.link.title}
            variant={'outline'}
            href={partnerCase.link.url}
          />
        )}
      </CardContentWrapper>
    </CardContainer>
  );
}
