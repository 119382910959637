import { TypeTestimonial } from '../../../@types/generated';
import { Document } from '@contentful/rich-text-types';

export type TestimonialCarouselItems = {
  author?: string;
  jobTitle?: string;
  logo?: string;
  logoAlt: string;
  logoWidth?: number;
  logoHeight?: number;
  imageUrl?: string;
  imageAlt: string;
  text?: Document;
  link?: {
    title?: string;
    url?: string;
  };
}[];

export function toTestimonialCarouselItem(
  testimonialItems?: (TypeTestimonial | undefined)[],
): TestimonialCarouselItems {
  return (
    testimonialItems?.map((item) => ({
      author: item?.fields.name,
      jobTitle: item?.fields.jobTitle,
      logo: item?.fields.mediaLogo?.fields.imageLandscape?.fields.file?.url,
      logoAlt:
        item?.fields.mediaLogo?.fields.imageLandscape?.fields.description ??
        item?.fields.mediaLogo?.fields.imageLandscape?.fields.title ??
        '',
      logoWidth: item?.fields.mediaLogo?.fields.imageLandscape?.fields.file?.details?.image?.width,
      logoHeight:
        item?.fields.mediaLogo?.fields.imageLandscape?.fields.file?.details?.image?.height,
      imageUrl:
        item?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields.file?.url,
      imageAlt:
        item?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields
          .description ??
        item?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields.title ??
        '',
      text: item?.fields.body,
      link: {
        title: item?.fields.link?.fields.identifier,
        url: item?.fields.link?.fields.externalUrl,
      },
    })) ?? []
  );
}
